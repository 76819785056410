document.addEventListener("DOMContentLoaded", function () {
  var header = document.getElementById("top-head");
  var navToggle = document.getElementById("nav-toggle");

  // Add fixed class when scrolling past a certain point
  window.addEventListener("scroll", function () {
    if (window.scrollY > 350) {
      header.classList.add("fixed");
    } else {
      header.classList.remove("fixed");
    }
  });

  // Toggle the open class on nav-toggle click
  navToggle.addEventListener("click", function () {
    header.classList.toggle("open");
  });
});
